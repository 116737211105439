'use client';

import { BlogPost } from '@/components/blog/blog-posts';
import { Typography } from '@/components/ui/typography';
import { format } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';
interface BlogPostCardProps {
  post: BlogPost;
  idx: number;
}
const BlogPostCard = ({
  post
}: BlogPostCardProps) => <Link href={`/blog/${post.slug}`} className="group" data-sentry-element="Link" data-sentry-component="BlogPostCard" data-sentry-source-file="blog-post-card.tsx">
		<article className="flex flex-col mb-6 p-4 mx-6 bg-background overflow-hidden rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:scale-105">
			<div className="aspect-[16/9] relative overflow-hidden">
				<Image src={post.image!} alt={post.title!} fill className="object-cover transition-transform duration-300 group-hover:scale-105" data-sentry-element="Image" data-sentry-source-file="blog-post-card.tsx" />
			</div>
			<div className="p-4 flex flex-col flex-grow">
				<Typography variant="h3" size="xl" className="font-semibold mb-2 group-hover:text-primary transition-colors line-clamp-2" data-sentry-element="Typography" data-sentry-source-file="blog-post-card.tsx">
					{post.title}
				</Typography>
				<Typography variant="p" size="sm" className="text-muted-foreground mb-4 line-clamp-3" data-sentry-element="Typography" data-sentry-source-file="blog-post-card.tsx">
					{post.description}
				</Typography>
				<div className="mt-auto flex justify-between items-center text-xs text-muted-foreground">
					<span>{post.author}</span>
					<span>
            {format(post.date!, 'MMM d, yyyy')} • {post.ttr} min read
          </span>
				</div>
			</div>
		</article>
	</Link>;
export default BlogPostCard;