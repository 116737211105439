'use client';

import NavigationLink from '@/components/navlinks/NavigationLink';
import { Button } from '@/components/ui/button';
import { motion } from 'framer-motion';
import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
type CTAButtonProps = {
  title: string;
  onClick?: () => void;
  href: string;
};
const CTAButton: React.FC<CTAButtonProps> = ({
  title,
  onClick,
  href
}) => {
  return <motion.div initial={{
    opacity: 0,
    y: 20
  }} animate={{
    opacity: 1,
    y: 0
  }} transition={{
    duration: 0.5
  }} data-sentry-element="unknown" data-sentry-component="CTAButton" data-sentry-source-file="cta-button.tsx">
			<Button onClick={onClick} asChild className="bg-primary group relative overflow-hidden text-white px-4 py-7 text-lg font-semibold rounded-full shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out" data-sentry-element="Button" data-sentry-source-file="cta-button.tsx">
				<NavigationLink href={href} data-sentry-element="NavigationLink" data-sentry-source-file="cta-button.tsx">
					<div className="flex items-center space-x-10 rtl:space-x-reverse">
						<span>{title}</span>
						<IoIosArrowForward className="size-5 transform group-hover:translate-x-1 transition-transform duration-200 ease-in-out rtl:rotate-180" data-sentry-element="IoIosArrowForward" data-sentry-source-file="cta-button.tsx" />
						<span className="absolute inset-0 w-full h-full bg-white/10 transform -skew-x-12 -translate-x-full group-hover:translate-x-0 ease-out duration-500 transition-transform" />
					</div>
				</NavigationLink>
			</Button>
		</motion.div>;
};
CTAButton.displayName = 'cta-button';
export default CTAButton;