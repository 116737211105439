"use client";

import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
const HeroButton = () => {
  const t = useTranslations("Hero");
  return <motion.div className="" initial={{
    opacity: 0,
    y: 20
  }} animate={{
    opacity: 1,
    y: 0
  }} transition={{
    duration: 0.5
  }} data-sentry-element="unknown" data-sentry-component="HeroButton" data-sentry-source-file="HeroButton.tsx">
      <Button asChild className="max-w-fit bg-gradient-custom-animated group relative overflow-hidden text-black px-4 py-6 rounded-full transition-all duration-300 ease-in-out" data-sentry-element="Button" data-sentry-source-file="HeroButton.tsx">
        <Link onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        window.scrollTo({
          top: window.innerHeight,
          behavior: "smooth"
        });
      }} href="/auth?redirect=/models" className="relative flex items-center bg-white/30 justify-between gap-6 w-full" data-sentry-element="Link" data-sentry-source-file="HeroButton.tsx">
          <span className="z-10 text-lg">{t("cta")}</span>
          <IoIosArrowForward className={`size-5 transition-transform duration-200 ease-in-out z-10
              ltr:group-hover:translate-x-1 rtl:group-hover:-translate-x-1
              rtl:rotate-180`} data-sentry-element="IoIosArrowForward" data-sentry-source-file="HeroButton.tsx" />
          <span className="absolute inset-0 w-full h-full bg-white/10 backdrop-blur-2xl" />
          <span className={`absolute inset-0 w-full h-full bg-white/40 transform -skew-x-12 transition-transform duration-500 ease-out
              ltr:translate-x-full ltr:group-hover:translate-x-0
              rtl:-translate-x-full rtl:group-hover:translate-x-0`} />
        </Link>
      </Button>
    </motion.div>;
};
HeroButton.displayName = "HeroButton";
export default HeroButton;