import CTAButton from '@/components/products/cta-button';
import { Typography } from '@/components/ui/typography';
import config, { Product } from '@/config';
import { motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React from 'react';
type AIShowCaseProps = {
  aiName: Product;
  Icon?: string | null;
};
const AIShowCase: React.FC<AIShowCaseProps> = ({
  aiName,
  Icon
}) => {
  const t = useTranslations(`Product.${aiName}`);
  const fadeInUpVariants = {
    hidden: {
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0
    }
  };
  const IconComponent = Icon ? <div className="aspect-[1/1] relative">
			<Image src={Icon} alt={aiName} fill className="object-fill rtl:scale-x-[-1]" />
		</div> : null;
  return <div className="flex flex-col md:flex-row items-center justify-between min-h-[75vh] w-full my-16 md:my-0" key={aiName} data-sentry-component="AIShowCase" data-sentry-source-file="product-show.tsx">
			<div className="w-full md:max-w-xl space-y-8">
				<motion.div className="space-y-4" initial="hidden" animate="visible" variants={fadeInUpVariants} transition={{
        duration: 0.8
      }} data-sentry-element="unknown" data-sentry-source-file="product-show.tsx">
					<Typography variant="h2" className="text-xl font-semibold tracking-wide bg-clip-text text-transparent bg-gradient-custom-animated uppercase" data-sentry-element="Typography" data-sentry-source-file="product-show.tsx">
						{t(`subtitle`)}
					</Typography>
					<Typography variant="h1" className="text-4xl md:text-6xl lg:text-7xl text-primary" data-sentry-element="Typography" data-sentry-source-file="product-show.tsx">
						{t(`title`)}
					</Typography>
				</motion.div>
				{IconComponent && <motion.div initial={{
        opacity: 0,
        scale: 0.8
      }} animate={{
        opacity: 1,
        scale: 1
      }} transition={{
        delay: 0.4,
        duration: 0.8
      }} className="md:hidden my-8">
						<div className="w-64 h-64 mx-auto">{IconComponent}</div>
					</motion.div>}
				<motion.div variants={fadeInUpVariants} initial="hidden" animate="visible" transition={{
        delay: 0.6,
        duration: 0.8
      }} data-sentry-element="unknown" data-sentry-source-file="product-show.tsx">
					<Typography variant="p" size="xl" className="text-primary leading-relaxed" data-sentry-element="Typography" data-sentry-source-file="product-show.tsx">
						{t(`description`)}
					</Typography>
				</motion.div>
				<motion.div variants={fadeInUpVariants} initial="hidden" animate="visible" transition={{
        delay: 0.8,
        duration: 0.8
      }} className="pt-4" data-sentry-element="unknown" data-sentry-source-file="product-show.tsx">
					<CTAButton href={config.getProductLink(aiName)} title={t(`cta`)} data-sentry-element="CTAButton" data-sentry-source-file="product-show.tsx" />
				</motion.div>
			</div>
			{IconComponent && <motion.div initial={{
      opacity: 0,
      scale: 0.8
    }} animate={{
      opacity: 1,
      scale: 1
    }} transition={{
      delay: 0.6,
      duration: 0.8
    }} className="hidden md:block">
					<div className="size-72 mx-auto">{IconComponent}</div>
				</motion.div>}
		</div>;
};
export default AIShowCase;