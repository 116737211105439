import { NEXT_PUBLIC_BASE_URL, NODE_ENV } from '@/env';



const products = {
	hami: 'https://hamichat.kheradmen.com',
	medguard: 'https://medguard.kheradmen.com',
	carestyle: 'https://carestyle.org',
	helina: 'https://helina.kheradmen.com',
	autonoma: '#',
	robovakil: 'https://robovakil.com'
} as const;

export const BASE_URL = NEXT_PUBLIC_BASE_URL || 'https://kheradmen.com';

export type Product = keyof typeof products;
export const getProductLink = (product: Product): string => products[product];
export const links = {
	home: '/',
	dashboard: '/dashboard',
	products
} as const;


export const themes = ['light', 'dark', 'system'] as const;
export type Themes = typeof themes[number];
export const defaultTheme = 'system';


const config = {
	BASE_URL,
	themes,
	defaultTheme,
	getProductLink,
	links
};

export default config;
export type Config = typeof config;


export const isDev = NODE_ENV === 'development';


