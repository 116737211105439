'use client';

import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
const CanvasGradient = dynamic(() => import('./hero-animation-bg'), {
  ssr: false,
  loading: () => <FallbackGradient />
});
const FallbackGradient: React.FC = () => <div className="w-full h-full bg-gradient-custom-animated" data-sentry-component="FallbackGradient" data-sentry-source-file="gradi-hero.tsx" />;
const CanvasTemplate: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  return <div className={`absolute -top-84 left-0 w-full h-[90vh] skew-y-9 ltr:-skew-y-9`} data-sentry-component="CanvasTemplate" data-sentry-source-file="gradi-hero.tsx">
			{children}
			<div className="bg-background/50 blur-xl inset-0 absolute" />
		</div>;
};
const CanvasGradientWrapper: React.FC = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return <CanvasTemplate data-sentry-element="CanvasTemplate" data-sentry-component="CanvasGradientWrapper" data-sentry-source-file="gradi-hero.tsx">
			{isMounted ? <CanvasGradient /> : <FallbackGradient />}
		</CanvasTemplate>;
};
export default CanvasGradientWrapper;