"use client";

import HeroButton from "@/components/hero/HeroButton";
import { Typography } from "@/components/ui/typography";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import React from "react";
import dynamic from "next/dynamic";
const AnimatedLogo = dynamic(() => import("./animated-icon"), {
  ssr: false
});
const HeroSection: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const t = useTranslations("Hero");
  return <section className={cn("min-h-screen container flex flex-col lg:flex-row", "items-center justify-center gap-8 lg:gap-16 py-12 lg:py-24 relative", className)} {...props} data-sentry-component="HeroSection" data-sentry-source-file="HeroSection.tsx">
      <motion.div initial={{
      opacity: 0,
      scale: 0.8,
      rotate: -5
    }} animate={{
      opacity: 1,
      scale: 1,
      rotate: 0
    }} transition={{
      duration: 0.8,
      ease: "easeOut"
    }} className="w-full flex justify-center items-center lg:justify-start order-1 lg:order-2 mb-8 lg:mb-0 relative" data-sentry-element="unknown" data-sentry-source-file="HeroSection.tsx">
        <div className="w-full sm:max-w-xs lg:max-w-md xl:max-w-lg px-4 sm:px-0 flex justify-center relative">
          <AnimatedLogo className="max-w-[350px] h-auto z-[20]" data-sentry-element="AnimatedLogo" data-sentry-source-file="HeroSection.tsx" />
        </div>
      </motion.div>
      <motion.div initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.6
    }} className="space-y-6 lg:space-y-16 max-w-2xl w-full z-10 order-2 lg:order-1" data-sentry-element="unknown" data-sentry-source-file="HeroSection.tsx">
        <div className="space-y-6 lg:space-y-10 rtl:space-y-8 lg:rtl:space-y-14 leading-loose text-foreground">
          <motion.div className="rtl:pt-4 lg:rtl:pt-12" initial={{
          opacity: 0,
          y: -20
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          delay: 0.2,
          duration: 0.5
        }} data-sentry-element="unknown" data-sentry-source-file="HeroSection.tsx">
            <Typography variant="h1" size="6xl-title" className={cn("font-extrabold text-start [text-shadow:_0_1px_2px_rgba(0,0,0,0.1)] lg:text-6xl-title", "rtl:whitespace-pre rtl:leading-[1.3] ltr:leading-tight")} data-sentry-element="Typography" data-sentry-source-file="HeroSection.tsx">
              {t("title")}
            </Typography>
          </motion.div>
          <motion.div initial={{
          opacity: 0,
          y: 20
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          delay: 0.4,
          duration: 0.5
        }} data-sentry-element="unknown" data-sentry-source-file="HeroSection.tsx">
            <Typography variant="p" className={cn("text-base lg:text-xl tracking-tight font-[500] opacity-65 text-start [text-shadow:_0_1px_1px_rgba(0,0,0,0.05)]", "rtl:lg:text-lg rtl:leading-[2] ltr:leading-relaxed")} data-sentry-element="Typography" data-sentry-source-file="HeroSection.tsx">
              {t("body")}
            </Typography>
          </motion.div>
        </div>
        <HeroButton data-sentry-element="HeroButton" data-sentry-source-file="HeroSection.tsx" />
      </motion.div>
    </section>;
};
HeroSection.displayName = "Hero";
export default HeroSection;