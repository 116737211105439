'use client';

import { Typography } from '@/components/ui/typography';
import { motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import React from 'react';
const numbers = [1, 2, 3] as const;
const AboutUsSection: React.FC = () => {
  const t = useTranslations('About');
  const fullTitle = t('h1');
  const [mainTitle, preTitle] = fullTitle.split(':').map(part => part.trim());
  const fadeInVariants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: {
      opacity: 1,
      y: 0
    }
  };
  return <motion.section id="about" className="min-h-screen container py-16" initial="initial" animate="animate" variants={{
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  }} data-sentry-element="unknown" data-sentry-component="AboutUsSection" data-sentry-source-file="AboutUs.tsx">
			<motion.div className="mx-auto space-y-12" variants={fadeInVariants} data-sentry-element="unknown" data-sentry-source-file="AboutUs.tsx">
				<div>
					<Typography variant="h2" size="lg" className="mb-2 tracking-wide bg-clip-text text-transparent bg-gradient-custom-animated uppercase" data-sentry-element="Typography" data-sentry-source-file="AboutUs.tsx">
						{preTitle}
					</Typography>
					<Typography variant="h1" size="title" className="font-bold text-foreground" data-sentry-element="Typography" data-sentry-source-file="AboutUs.tsx">
						{mainTitle}
					</Typography>
				</div>
				<Typography variant="p" size="base" className="text-foreground/80 leading-relaxed" data-sentry-element="Typography" data-sentry-source-file="AboutUs.tsx">
					{t('p.1')}
				</Typography>
				<ul className="space-y-6">
					{numbers.map(i => <motion.li key={i} className="flex items-start" variants={fadeInVariants}>
							<span className="mr-3 rtl:ml-3 text-primary text-3xl">•</span>
							<div>
								<Typography variant="h3" size="md" className="font-semibold tracking-wide rtl:text-primary ltr:bg-clip-text ltr:text-transparent ltr:bg-gradient-custom-animated uppercase mb-2">
									{t(`li.${i}`).split(':')[0]}
								</Typography>
								<Typography variant="p" size="sm" className="text-foreground/80">
									{t(`li.${i}`).split(':')[1]}
								</Typography>
							</div>
						</motion.li>)}
				</ul>
				<Typography variant="p" size="base" className="text-foreground/80 leading-relaxed" data-sentry-element="Typography" data-sentry-source-file="AboutUs.tsx">
					{t('p.2')}
				</Typography>
			</motion.div>
		</motion.section>;
};
export default AboutUsSection;