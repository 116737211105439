'use client';

import AIShowCase from '@/components/products/product-show';
import { Product } from '@/config';
import { cn } from '@/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { Cpu, Scale, Stethoscope } from 'lucide-react';
import { useTranslations } from 'next-intl';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
type IconProps = React.ComponentProps<typeof Cpu>;
type Category = {
  id: 'medical' | 'technology' | 'juridical';
  Icon: React.ComponentType<IconProps>;
};
type Model = {
  cat: string;
  name: Product;
  icon: string | null;
};
const categories: Category[] = [{
  id: 'medical',
  Icon: Stethoscope
}, {
  id: 'technology',
  Icon: Cpu
}, {
  id: 'juridical',
  Icon: Scale
}];
const importIcon = (name: string) => `/img/icons/${name}.svg`;
const models: Model[] = [{
  cat: 'medical',
  name: 'hami',
  icon: importIcon('hami')
}, {
  cat: 'medical',
  name: 'medguard',
  icon: importIcon('medguard')
}, {
  cat: 'medical',
  name: 'carestyle',
  icon: importIcon('carestyle')
}, {
  cat: 'medical',
  name: 'helina',
  icon: importIcon('helina')
}, {
  cat: 'technology',
  name: 'autonoma',
  icon: null
}, {
  cat: 'juridical',
  name: 'robovakil',
  icon: null
}];
const NAVBAR_HEIGHT = 55;
const ProductSection = () => {
  const t = useTranslations('Product');
  const [activeCategory, setActiveCategory] = useState<string>(categories[0].id);
  const [direction, setDirection] = useState<number>(0);
  const [isFilterFloating, setIsFilterFloating] = useState<boolean>(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (sectionRef.current && filterRef.current) {
      const sectionRect = sectionRef.current.getBoundingClientRect();
      const filterRect = filterRef.current.getBoundingClientRect();
      const shouldFloat = sectionRect.top <= NAVBAR_HEIGHT && sectionRect.bottom > filterRect.height + NAVBAR_HEIGHT;
      setIsFilterFloating(shouldFloat);
    }
  }, []);
  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 100);
    window.addEventListener('scroll', throttledHandleScroll);
    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, [handleScroll]);
  useEffect(() => {
    if (filterRef.current && contentRef.current) {
      const filterHeight = filterRef.current.offsetHeight;
      contentRef.current.style.marginTop = isFilterFloating ? `${filterHeight}px` : '0';
    }
  }, [isFilterFloating]);
  const handleCategoryChange = useCallback((newCategory: string) => {
    const oldIndex = categories.findIndex(cat => cat.id === activeCategory);
    const newIndex = categories.findIndex(cat => cat.id === newCategory);
    setDirection(newIndex > oldIndex ? 1 : -1);
    setActiveCategory(newCategory);
  }, [activeCategory]);
  const slideVariants = {
    enter: (direction: number) => ({
      y: direction > 0 ? '100%' : '-100%',
      opacity: 0
    }),
    center: {
      y: 0,
      opacity: 1
    },
    exit: (direction: number) => ({
      y: direction < 0 ? '100%' : '-100%',
      opacity: 0
    })
  };
  const filteredModels = useMemo(() => models.filter(item => item.cat === activeCategory), [activeCategory]);
  return <div id="products" ref={sectionRef} className="bg-card/50 text-foreground min-h-screen py-12 overflow-hidden" data-sentry-component="ProductSection" data-sentry-source-file="productSection.tsx">
			<div className="container mx-auto">
				<div ref={filterRef} className={cn('transition-all duration-300 ease-in-out', isFilterFloating && 'fixed left-0 right-0 top-[85px] z-10')}>
					<motion.div className="flex justify-center transition-all duration-300 ease-in-out mx-auto mb-12" initial={{
          opacity: 0,
          y: -20
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          duration: 0.5
        }} data-sentry-element="unknown" data-sentry-source-file="productSection.tsx">
						<div className="rounded-full p-2 bg-card/50 backdrop-blur-sm shadow-lg">
							{categories.map(({
              id,
              Icon
            }) => <motion.button key={id} onClick={() => handleCategoryChange(id)} className={`relative px-6 py-3 rounded-full transition-all duration-300
                    ${activeCategory === id ? 'text-primary' : 'text-muted-foreground hover:text-foreground'}`} whileHover={{
              scale: 1.05
            }} whileTap={{
              scale: 0.95
            }}>
                  <span className="flex items-center space-x-2 rtl:space-x-reverse">
                    <Icon />
                    <span className="font-medium">{t(`category.${id}`)}</span>
                  </span>
									{activeCategory === id && <motion.div className="absolute inset-0 bg-primary/10 rounded-full z-0" layoutId="activeBackground" initial={{
                opacity: 0
              }} animate={{
                opacity: 1
              }} exit={{
                opacity: 0
              }} />}
								</motion.button>)}
						</div>
					</motion.div>
				</div>

				<div ref={contentRef}>
					<AnimatePresence initial={false} custom={direction} mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="productSection.tsx">
						<motion.div key={activeCategory} custom={direction} variants={slideVariants} initial="enter" animate="center" exit="exit" transition={{
            y: {
              type: 'spring',
              stiffness: 300,
              damping: 30
            },
            opacity: {
              duration: 0.2
            }
          }} data-sentry-element="unknown" data-sentry-source-file="productSection.tsx">
							{filteredModels.map((item, index) => <AIShowCase key={index} aiName={item.name} Icon={item.icon} />)}
						</motion.div>
					</AnimatePresence>
				</div>
			</div>
		</div>;
};
const throttle = (func: (...args: unknown[]) => void, limit: number) => {
  let inThrottle = false;
  return (...args: unknown[]) => {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
};
export default memo(ProductSection);