'use client';

import { cn } from '@/lib/utils';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import React from 'react';
interface CareerBannerProps {
  className?: string;
}
const CareerBanner: React.FC<CareerBannerProps> = ({
  className = ''
}) => {
  const t = useTranslations('CareerBanner');
  const locale = useLocale();
  const isRTL = locale === 'fa';
  const Arrow = isRTL ? ArrowLeft : ArrowRight;
  return <div className={cn('container bg-gradient-to-r from-primary to-secondary text-white p-8 rounded-lg shadow-lg', 'transform hover:scale-[1.02] transition-transform duration-300', className)} data-sentry-component="CareerBanner" data-sentry-source-file="banner.tsx">
			<div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
				<div className="text-center md:text-left rtl:md:text-right">
					<h2 className="text-3xl font-bold mb-3">
						{t('title')}
					</h2>
					<p className="text-blue-100 text-lg">
						{t('description')}
					</p>
				</div>
				<Link href={`/${locale}/careers`} className={cn('inline-flex items-center px-6 py-3 text-lg font-medium rounded-full', 'bg-white text-primary hover:bg-opacity-90 transition duration-300', 'transform hover:translate-x-1 rtl:hover:-translate-x-1')} data-sentry-element="Link" data-sentry-source-file="banner.tsx">
					{t('applyNow')}
					<Arrow className={cn('h-5 w-5', isRTL ? 'mr-2 rtl:ml-2' : 'ml-2 rtl:mr-2')} data-sentry-element="Arrow" data-sentry-source-file="banner.tsx" />
				</Link>
			</div>
		</div>;
};
export default CareerBanner;